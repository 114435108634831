<template>
  <div class="col">
    <card class="card-user position-relative">
      <!-- Start Information -->
      <div class="main-image-card user_bg_img">
        <div class="new_user_background"></div>
      </div>
      <div>
        <div class="author">
          <el-upload
            v-if="this.auth().id === user.id"
            class="upload-demo"
            :show-file-list="false"
            accept=".png,.jpg"
            :on-success="Refresh"
            :action="upload_src"
          >
            <i
              class="fa fa-edit hide-edit"
              style="
                position: relative;
                left: 0;
                top: 8.7rem;
                z-index: 1001;
                font-weight: bolder;
                font-size: 16px;
              "
            ></i>
          </el-upload>
          <img class="avatar border-dark" :src="image" alt="..." />
          <div class="main_auth_user_info">
            <span class="d-block mb-2 n_name">{{
              `${user.first_name} ${user.last_name}`
            }}</span>
            <small class="n_email"> {{ user.email }}</small>
          </div>
          <div class="which_user_type mt-2 mb-3">
            <div class="nn">
              <span
                class="classification"
                v-if="user.classification == 'Bridge'"
                >{{ localization("user type bridge") }}</span
              >
              <span
                class="classification"
                v-if="user.classification == 'Dynamic'"
                >{{ localization("User type dynamic") }}</span
              >
              <span
                class="classification"
                v-if="user.classification == 'Strong-Willed Learner'"
                >{{ localization("user type SWL") }}</span
              >

              <div class="another_content">
                <span
                  class="tit text-uppercase"
                  v-if="user.classification == 'Bridge'"
                  >{{ localization("user type bridge") }}
                  {{ localization("definition") }}
                </span>
                <span
                  class="tit text-uppercase"
                  v-if="user.classification == 'Dynamic'"
                  >{{ localization("User type dynamic") }}
                  {{ localization("definition") }}</span
                >
                <span
                  class="tit text-uppercase"
                  v-if="user.classification == 'Strong-Willed Learner'"
                  >{{ localization("user type SWL") }}
                  {{ localization("definition") }}</span
                >

                <!-- Description Text  -->
                <span
                  class="tex d-block"
                  v-if="user.classification == 'Bridge'"
                >
                  {{ localization("bridge tool tip") }}
                </span>
                <span
                  class="tex d-block"
                  v-if="user.classification == 'Dynamic'"
                >
                  {{ localization("dynamic tool tip") }}
                </span>
                <span
                  class="tex d-block"
                  v-if="user.classification == 'Strong-Willed Learner'"
                >
                  {{ localization("swl to tip") }}
                </span>
              </div>
              <!-- Dynamic -->
              <!-- Strong-Willed Learner -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Information -->

      <!-- ////start Score Cards  -->
      <!--// start  Medium & Small Screens  -->
      <div class="row my-4 d-lg-none main-card-print_small">
        <div class="col-sm-12 d-flex justify-content-center flex-column">
          <!-- Sequence  -->
          <div class="when_joel_mobile">
            <div class="d-flex align-items-center">
              <p class="label sequence">
                {{ userScore.sequence != null ? userScore.sequence : 0 }}
              </p>
              <span style="font-size: 1.3rem; text-transform: capitalize">{{
                localization("Sequence")
              }}</span>
            </div>
            <div class="hello_text">
              <span class="d-block text-uppercase ti">{{
                localization("SEQUENTIAL LEARNING PATTERNS")
              }}</span>
              <span class="d-block te">{{
                localization("Organizes life")
              }}</span>
              <span class="d-block te">{{ localization("Makes Lists") }}</span>
              <span class="d-block te">{{
                localization("Lives by schedules")
              }}</span>
              <span class="d-block te">{{
                localization("Makes extensive plans")
              }}</span>
            </div>
          </div>
          <div class="when_joel_mobile">
            <div class="d-flex align-items-center">
              <p class="label precision">
                {{ userScore.precision != null ? userScore.precision : 0 }}
              </p>
              <span style="font-size: 1.3rem; text-transform: capitalize">{{
                localization("precision")
              }}</span>
            </div>
            <div class="hello_text">
              <span class="d-block text-uppercase ti">{{
                localization("PRECISION LEARNING PATTERNS")
              }}</span>
              <span class="d-block te">{{
                localization("Relishes information")
              }}</span>
              <span class="d-block te">{{ localization("Seeks facts") }}</span>
              <span class="d-block te">{{
                localization("Must be right")
              }}</span>
              <span class="d-block te">{{
                localization("Provides detailed responses")
              }}</span>
            </div>
          </div>
          <!-- Technical Reasoning  -->
          <div class="when_joel_mobile">
            <div class="d-flex align-items-center">
              <p class="label technical_reasoning">
                {{
                  userScore.technical_reasoning != null
                    ? userScore.technical_reasoning
                    : 0
                }}
              </p>
              <span style="font-size: 1.3rem">{{
                localization("Technical Reasoning")
              }}</span>
            </div>
            <div class="hello_text">
              <span class="d-block text-uppercase ti">{{
                localization("TECHNICAL LEARNING PATTERNS")
              }}</span>
              <span class="d-block te">{{
                localization("Solves problems")
              }}</span>
              <span class="d-block te">{{
                localization("Uses few words")
              }}</span>
              <span class="d-block te">{{
                localization("Looks for relevance and practicality")
              }}</span>
              <span class="d-block te">{{ localization("Works alone") }}</span>
            </div>
          </div>
          <!-- Confluence  -->
          <div class="when_joel_mobile">
            <div class="d-flex align-items-center">
              <p class="label confluence">
                {{ userScore.confluence != null ? userScore.confluence : 0 }}
              </p>
              <span style="font-size: 1.3rem; text-transform: capitalize">{{
                localization("Confluence")
              }}</span>
            </div>
            <div class="hello_text">
              <span class="d-block text-uppercase ti">{{
                localization("CONFLUENCE LEARNING PATTERNS")
              }}</span>
              <span class="d-block te">{{ localization("Takes risks") }}</span>
              <span class="d-block te">{{
                localization("Sees the big picture")
              }}</span>
              <span class="d-block te">{{
                localization("Embraces innovation")
              }}</span>
              <span class="d-block te">{{
                localization("Initiates change")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!--// end Medium & Small Screens  -->
      <!-- // Start Large Screens  -->
      <div class="row my-4 d-none d-lg-block main-card-print">
        <div
          class="col-sm-12 px-0 px-lg-3 d-flex align-items-center the_patterns_with_new_theme"
        >
          <!-- Start Sequence  -->
          <div style="min-width: 25%;">
            <card
              :height="`${userScore.sequence * 2.5 + 120}px`"
              style="
              background-color: #39c;
              border-radius: 0;

              width: 100%;
              max-height: 230px;
              min-height: 120px;
            "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->
                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 2rem"
                >
                  {{ localization("Sequence") }}
                </p>
                <div
                  class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("Sequence shortcut") }}
                  </p>
                  <!-- Score Number  -->

                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 50 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize: (userScore.sequence / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{ userScore.sequence != null ? userScore.sequence : 0 }}
                  </p>
                </div>
              </div>
            </card>
            <div class="new_theme_pattern_content">
              <div class="main_content_container with_sequence">
                <span class="d-block text-uppercase ti">{{
                  localization("SEQUENTIAL LEARNING PATTERNS")
                }}</span>
                <span class="d-block te">{{
                  localization("Organizes life")
                }}</span>
                <span class="d-block te">{{
                  localization("Makes Lists")
                }}</span>
                <span class="d-block te">{{
                  localization("Lives by schedules")
                }}</span>
                <span class="d-block te">{{
                  localization("Makes extensive plans")
                }}</span>
              </div>
            </div>
          </div>
          <!-- End Sequence  -->

          <!-- Start Precision   -->
          <div style="min-width: 25%;">
            <card
              :height="`${userScore.precision * 2.5 + 120}px`"
              style="
              border-radius: 0;

              min-width: 25%;
              background-color: #3c3;
              max-height: 230px;
              min-height: 120px;
            "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->

                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 2rem"
                >
                  {{ localization("precision") }}
                </p>

                <div
                  class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("precision shortcut") }}
                  </p>
                  <!-- Score Number  -->
                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize: (userScore.precision / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{ userScore.precision != null ? userScore.precision : 0 }}
                  </p>
                </div>
              </div>
            </card>
            <div class="new_theme_pattern_content">
              <div class="main_content_container with_precision">
                <span class="d-block text-uppercase ti">{{
                  localization("PRECISION LEARNING PATTERNS")
                }}</span>
                <span class="d-block te">{{
                  localization("Relishes information")
                }}</span>
                <span class="d-block te">{{
                  localization("Seeks facts")
                }}</span>
                <span class="d-block te">{{
                  localization("Must be right")
                }}</span>
                <span class="d-block te">{{
                  localization("Provides detailed responses")
                }}</span>
              </div>
            </div>
          </div>
          <!-- End Precision   -->
          <!-- Start Technical Reasoning  -->
          <div style="min-width: 25%;">
            <!-- border: 1px solid #ccc; -->
            <card
              :height="`${userScore.technical_reasoning * 2.5 + 120}px`"
              style="
                border-radius: 0;

                min-width: 25%;
                background-color: #f93;
                max-height: 230px;
                min-height: 120px;
              "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->
                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 1.6rem"
                >
                  {{ localization("Technical Reasoning") }}
                </p>
                <div
                  class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                    flex-column flex-md-row
                  "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("Technical Reasoning shortcut") }}
                  </p>
                  <!-- Score Number  -->
                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize:
                        (userScore.technical_reasoning / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{
                      userScore.technical_reasoning != null
                        ? userScore.technical_reasoning
                        : 0
                    }}
                  </p>
                </div>
              </div>
            </card>
            <div class="new_theme_pattern_content">
              <div class="main_content_container with_technical_reasoning">
                <span class="d-block text-uppercase ti">{{
                  localization("TECHNICAL LEARNING PATTERNS")
                }}</span>
                <span class="d-block te">{{
                  localization("Solves problems")
                }}</span>
                <span class="d-block te">{{
                  localization("Uses few words")
                }}</span>
                <span class="d-block te">{{
                  localization("Looks for relevance and practicality")
                }}</span>
                <span class="d-block te">{{
                  localization("Works alone")
                }}</span>
              </div>
            </div>
          </div>
          <!-- End Technical Reasoning  -->
          <!--Start Confluence  -->
          <div style="min-width: 25%;">
            <card
              :height="`${userScore.confluence * 2.5 + 120}px`"
              style="
                    border-radius: 0;

                    min-width: 25%;
                    background-color: #c36;
                    max-height: 230px;
                    min-height: 120px;
                  "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->
                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 2rem"
                >
                  {{ localization("Confluence") }}
                </p>

                <div
                  class="
                        d-flex
                        align-items-center align-items-xxl-start
                        justify-content-center justify-content-lg-around
                      "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("Confluence shortcut") }}
                  </p>
                  <!-- Score Number  -->
                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize: (userScore.confluence / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{
                      userScore.confluence != null ? userScore.confluence : 0
                    }}
                  </p>
                </div>
              </div>
            </card>
            <div class="new_theme_pattern_content">
              <div class="main_content_container with_confluence">
                <span class="d-block text-uppercase ti">{{
                  localization("CONFLUENCE LEARNING PATTERNS")
                }}</span>
                <span class="d-block te">{{
                  localization("Takes risks")
                }}</span>
                <span class="d-block te">{{
                  localization("Sees the big picture")
                }}</span>
                <span class="d-block te">{{
                  localization("Embraces innovation")
                }}</span>
                <span class="d-block te">{{
                  localization("Initiates change")
                }}</span>
              </div>
            </div>
          </div>
          <!--End Confluence  -->
        </div>

        <!-- Start New Section  -->
        <div class="wrapper_for_use_first col-sm-12 mt-5 px-0 px-lg-3">
          <div class="main_co">
            <div class="a_one with_title">
              <span class="text-uppercase">{{
                localization("DEGREE OF USE")
              }}</span>
              <span>:</span>
            </div>
            <div class="a_one ">
              <span class="text-capitalize">{{
                localization("Use First")
              }}</span>
              <span>:</span>
              <span class="text-uppercase">35 - 25</span>
            </div>
            <div class="a_one a_two">
              <span class="text-capitalize">{{
                localization("As Needed")
              }}</span>
              <span>:</span>
              <span class="text-uppercase">24 - 18</span>
            </div>
            <div class="a_one ">
              <span class="text-capitalize">{{ localization("Avoid") }}</span>
              <span>:</span>
              <span class="text-uppercase">17 - 7</span>
            </div>
          </div>
        </div>
      </div>
      <!-- //  Large Screens  -->
      <!-- //// end Score Cards  -->
    </card>

    <card>
      <!-- Start For Family Users -->
      <div
        class="for_family"
        v-if="auth().family_type == 1 || auth().family_type == 2"
      >
        <div class="mb-3" v-if="userDashboardFamilyCms != null">
          <div v-html="userDashboardFamilyCms[0].body"></div>
        </div>

        <!-- <router-link class="start_with_the_lci" to="edit-questionnaire">
          {{ localization("Start with the LCI") }}</router-link
        > -->
      </div>
      <!-- End For Family Users -->
      <!-- Start For All Users -->
      <div class="for_all " v-else>
        <div class="mb-3 " v-if="userDashboardCms != null">
          <!-- <div v-html="userDashboardCms[0].body"></div> -->
          <!-- <div>{{ userStatusCards}}</div> -->
          <div class="mt-4 mb-4 main_strategy_container ">
            <div class="row">
              <!-- LCI -->
              <div class=" col-md-4">
                <router-link to="/edit-questionnaire">
                  <div class="cms_strategy_wrapper shadow-sm">
                    <span
                      class="icon_container"
                      v-if="userStatusCards.profile == 1"
                    >
                      <i class="fa fa-check"></i>
                    </span>
                    <div v-if="newDesignCards[1] != null">
                      <div v-html="newDesignCards[1].body"></div>
                    </div>
                  </div>
                </router-link>
              </div>
              <!--  PROFILE -->
              <div class=" col-md-4">
                <router-link to="/profile">
                  <div class="cms_strategy_wrapper shadow-sm">
                    <span
                      class="icon_container"
                      v-if="userStatusCards.pattern == 1"
                    >
                      <i class="fa fa-check"></i>
                    </span>
                    <div v-if="newDesignCards[2] != null">
                      <div v-html="newDesignCards[2].body"></div>
                    </div>
                  </div>
                </router-link>
              </div>
              <!--  pattern-->
              <div class=" col-md-4">
                <router-link to="/tasks">
                  <div class="cms_strategy_wrapper shadow-sm">
                    <span
                      class="icon_container"
                      v-if="userStatusCards.coaching == 1"
                    >
                      <i class="fa fa-check"></i>
                    </span>
                    <div v-if="newDesignCards[3] != null">
                      <div v-html="newDesignCards[3].body"></div>
                    </div>
                  </div>
                </router-link>
              </div>
              <!-- team -->
              <div class=" col-md-4">
                <router-link to="/teams">
                  <div class="cms_strategy_wrapper shadow-sm">
                    <span
                      class="icon_container"
                      v-if="userStatusCards.lci == 1"
                    >
                      <i class="fa fa-check"></i>
                    </span>
                    <div v-if="newDesignCards[0] != null">
                      <div v-html="newDesignCards[0].body"></div>
                    </div>
                  </div>
                </router-link>
              </div>
              <!-- COACHING -->
              <div class=" col-md-4">
                <div class="cms_strategy_wrapper shadow-sm">
                  <span class="icon_container" v-if="userStatusCards.team == 1">
                    <i class="fa fa-check"></i>
                  </span>
                  <div v-if="newDesignCards[4] != null">
                    <div v-html="newDesignCards[4].body"></div>
                  </div>
                </div>
              </div>
              <!-- coaching -->
            </div>
          </div>
        </div>

        <!-- <router-link class="start_with_the_lci" to="edit-questionnaire">
          {{ localization("Start with the LCI") }}</router-link
        > -->
      </div>
      <!-- End For All Users -->
    </card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userDashboardCms: null,
      userDashboardFamilyCms: null,
      userStatusCards: {},
      newCards: [],
      newDesignCards: []
    };
  },
  mounted() {
    this.Refresh();
  },
  computed: {
    user: function() {
      return this.$store.state.user.user;
    },
    id() {
      if (this.user_id) {
        return this.user_id;
      }
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    upload_src: function() {
      return `${process.env.VUE_APP_BASE_URL}/users/update_photo/${this.user.id}`;
    },
    image: function() {
      if (this.user.image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${this.user.image}`;
      }
      return "/static/img/lml-swerl.jpg";
    }
  },
  methods: {
    Refresh() {
      this.$store.dispatch("user/user", this.id);
      this.$store.dispatch("user/handlerUserDashboardCms").then(response => {
        this.userDashboardCms = response.user_dashboard;
      });
      this.$store.dispatch("user/handlerUserDashboardCmsCards").then(res => {
        this.userStatusCards = res;
      });
      this.$store
        .dispatch("user/handlerUserDashboardFamilyCms")
        .then(response => {
          this.userDashboardFamilyCms = response.family_dashboard;
        });
      this.$store.dispatch("user/handlerUserLciCms").then(response => {
        this.newCards = response.lci_instructions;
      });
      this.$store
        .dispatch("user/handlerUserNewDashboardCmsCards")
        .then(response => {
          this.newDesignCards = response.dashboard_cards;
        });
    }
    // switchToRoute(status, routeName){
    //   // /edit-questionnaire
    //   // profile
    //   // /teams
    // }
  }
};
</script>

<style lang="scss" scoped>
$just_theme_color: #00563f;

.main_strategy_container {
  // display: flex;
  // gap: 4%;
  // page-break-inside: avoid;
  // @media (min-width: 320px) and (max-width: 991px) {
  //   flex-wrap: wrap;
  // }
  // @media print {
  //   flex-wrap: nowrap;
  //   margin-top: 40px !important;
  //   page-break-after: always;
  // }
  .cms_strategy_wrapper {
    // width: calc(96% / 2);
    padding: 15px;
    color: #777;
    border-radius: 10px;
    border: 1px solid #00563f;
    min-height: 215px;
    margin-block: 15px;
    position: relative;
    // @media (min-width: 320px) and (max-width: 991px) {
    //   margin-bottom: 20px;
    // }
    .icon_container {
      background-color: #00563f;
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      top: -15px;
      left: calc(50% - 15px);
    }
  }
}
.when_joel_mobile {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
}
::v-deep .precision {
  margin: 5px;
  display: inline;
  background-color: #3c3;
  @media print {
    margin: 50px;
    display: inline;
    background-color: #3c3;
  }
}

::v-deep .technical_reasoning {
  display: inline;
  margin: 5px;
  background-color: #f93;
}

::v-deep .sequence {
  display: inline;
  margin: 5px;
  background-color: #39c;
}

::v-deep .confluence {
  display: inline;
  margin: 5px;
  background-color: #c36;
}

.label {
  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}
.letter {
  font-size: 40px;
}
.for_all {
  padding: 20px 0;
  font-size: 17px;
  line-height: 1.7;
  p {
    font-size: 17px;
    line-height: 1.7;
    margin-top: 20px;
  }
}
.for_family {
  padding: 20px 0;
  font-size: 17px;
  line-height: 1.7;
  p {
    font-size: 17px;
    line-height: 1.7;
    margin-top: 20px;
  }
}
.start_with_the_lci {
  background: #00563f;
  color: #fff;
  padding: 10px 16px;
  border-radius: 5px;
}

//  Start Information Style
.card-user .card-body {
  overflow: hidden;
}
.author {
  overflow: inherit;
}
.user_bg_img {
  height: 150px;
  .new_user_background {
    background-color: $just_theme_color;
    height: 100%;
    margin: auto;
  }
}
.main_auth_user_info {
  .n_name {
    font-size: 18px;
    color: #212529;
    font-weight: 300;
    text-transform: capitalize;
  }
  .n_email {
    font-size: 17px;
    color: #9a9a9a;
    font-weight: 300;
  }
}
.which_user_type {
  width: fit-content;
  margin: auto;
  transition: 0.5s all;
  .nn {
    // background-color: $just_theme_color;
    color: #fff;
    padding: 7px 10px;
    position: relative;
    transition: 0.5s all;

    &::before {
      position: absolute;
      content: "";
      background: #91b9ae;
      width: 55px;
      height: 10px;
      bottom: 8px;
      right: -38px;
      display: none;
      // opacity: 0;
      transition: 0.5s all;
      @media (min-width: 320px) and (max-width: 1150px) {
        // z-index: 77;
        // width: 10px;
        // height: 40px;
        // bottom: 51px;
        // right: auto;
        display: none;
      }
    }
    .classification {
      position: relative;
      z-index: 6;
      padding: 0 10px;
      min-width: 180px;
      // padding: 7px 10px;
      border-radius: 12px;
      background-color: $just_theme_color;
      height: 45px;
      display: block;
      line-height: 2.3;
      font-size: 18px;
    }
  }
  .another_content {
    display: none;
    // opacity: 0;
    position: absolute;
    bottom: 8px;
    width: 358px;
    background: #91b9ae;
    border-radius: 10px;
    padding: 10px;
    right: -360px;
    transition: 0.5s all;
    z-index: 9999999999;
    @media (min-width: 1150px) and (max-width: 1280px) {
      right: -290px;
      width: 294px;
    }
    @media (min-width: 320px) and (max-width: 1150px) {
      right: auto;
      left: -60px;
      bottom: 60px;

      &::before {
        position: absolute;
        content: "";
        background: #91b9ae;
        height: 10px;
        width: 10px;
        bottom: -8px;
        left: 50%;
      }
    }
    @media (min-width: 320px) and (max-width: 567px) {
      width: 323px;
    }
    .tit {
      font-size: 15px;
    }
    .tex {
      font-size: 13px;
    }
  }
  &:hover {
    .nn {
      &::before {
        display: block;
        // opacity: 1;
      }
    }
    .another_content {
      display: block !important;
      // opacity: 1;
    }
    @media (min-width: 320px) and (max-width: 1150px) {
      .nn {
        &::before {
          display: none;
          // opacity: 0;
        }
      }
    }
  }
  @media print {
    display: block !important;
  }
}

.the_patterns_with_new_theme {
  position: relative;

  .new_theme_pattern_content {
    padding: 0 10px;
    .main_content_container {
      border-left: 2px solid;
      border-right: 2px solid;
      border-bottom: 2px solid;
      text-align: center;
      padding: 10px 5px;
      &.with_sequence {
        border-color: #39c;
      }
      &.with_precision {
        border-color: #3c3;
      }
      &.with_technical_reasoning {
        border-color: #f93;
      }
      &.with_confluence {
        border-color: #c36;
      }
      .ti {
        // color: #555;
        font-size: 15px;
      }
      .te {
        color: #777;
        font-size: 13px;
      }
    }
  }
}

.wrapper_for_use_first {
  .main_co {
    background-color: #eee;
    display: flex;
    justify-content: center;
    gap: 10px;
    color: #7d7979;
    padding: 15px;
    .a_one {
      &.with_title {
        color: #555;
      }
    }
    .a_two {
      border-left: 3px solid #777;
      border-right: 3px solid #777;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
